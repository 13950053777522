import { defineComponent, PropType } from 'vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import type { TLeftColumnState } from '@/types/modelViewerTypes';
import { Foreshortening } from '@/types/foreshorteningTypes';

export default defineComponent({
  name: 'ControlBlock',

  components: {
    BaseIconButton
  },

  props: {
    leftColumnState: {
      type: Object as PropType<TLeftColumnState>,
      required: true
    },
    rightColumnState: {
      type: Object as PropType<TLeftColumnState>,
      required: true
    },
    isForCompare: {
      type: Boolean,
      default: false
    }
  },

  emits: ['clickModelPart', 'clickSecantPlane', 'clickForeshorteningType', 'clickWireFrameRender'],

  setup(_, { emit }) {
    const handleClickModelPart = () => {
      emit('clickModelPart');
    };
    const handleClickWireFrameRender = () => {
      emit('clickWireFrameRender');
    };

    const handleClickSecantPlane = () => {
      emit('clickSecantPlane');
    };

    const handleClickForeshorteningButton = (type: Foreshortening) => {
      emit('clickForeshorteningType', type);
    };

    return {
      handleClickWireFrameRender,
      handleClickModelPart,
      handleClickForeshorteningButton,
      handleClickSecantPlane,
      Foreshortening
    };
  }
});
