import { defineComponent, watch, PropType, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TMeshState } from '@/store/modules/models/state';
import { findMesh, watchMeshGroupState } from '@/components/Model/utils/setMeshState';
import { highlightText } from '@/components/Model/PageModelView/components/ColumnModelPart/components/utils/highlightText';
import _ from 'lodash';

export default defineComponent({
  name: 'ModelPartLine',

  components: {},

  props: {
    data: {
      type: Object as PropType<TMeshState>,
      required: true
    },
    searchValue: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const { commit, state } = useStore();
    const highlightedText = ref({
      string: _.escape(props.data.name),
      isMatched: true
    });

    const isOpenedLine = computed(() => {
      return (state.models?.mapMeshState[props.data.id]?.isOpen === undefined) ? true : state.models?.mapMeshState[props.data.id]?.isOpen;
    });

    const isVisibleGroup = computed(() => {
      return (state.models?.mapMeshState[props.data.id]?.isVisible === undefined) ? true : state.models?.mapMeshState[props.data.id]?.isVisible;
    });

    const toggleGroup = () => {
      const newMeshesState = state.models?.mapMeshState;
      newMeshesState[props.data.id].isOpen = !newMeshesState[props.data.id].isOpen;
      commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_MAP_MESH_STATE}`, newMeshesState);
    };

    const toggleVisibleMesh = () => {
      const changedState = findMesh(props.data.id, state.models.meshState);
      const newMeshesState = watchMeshGroupState(changedState);
      commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_MESH_STATE}`, newMeshesState);
    };

    const search = () => {
      highlightedText.value = highlightText(props.data.name, props.searchValue);
    };

    watch(() => props.searchValue, () => {
      search();
    });

    return {
      isVisibleGroup,
      isOpenedLine,
      highlightedText,
      toggleVisibleMesh,
      toggleGroup
    };
  }
});
