<template>
  <Column
    title="секущая плоскость"
    column-position="left"
    @closedColumn="closedColumn"
    className="secant-content"
  >
    <template v-slot:content>
      <SecantPlaneBlock
        label="Положение"
        :min="0"
        :max="1"
        :step=".01"
        :minFractionDigits="0"
        :maxFractionDigits="2"
        :value="secantValues.position"
        @changeSecantValue="changeSecantValue($event, Secant.position)"
      />
      <SecantPlaneBlock
        label="Поворот"
        :min="-180"
        :max="180"
        :step="1"
        :value="secantValues.turn"
        @changeSecantValue="changeSecantValue($event, Secant.turn)"
      />
      <SecantPlaneBlock
        label="Наклон"
        :min="-180"
        :max="180"
        :step="1"
        :value="secantValues.incline"
        @changeSecantValue="changeSecantValue($event, Secant.incline)"
      />
    </template>
  </Column>
</template>

<script src="./ColumnSecantPlane.ts" lang="ts"/>
<style src="./ColumnSecantPlane.scss" lang="scss"/>
