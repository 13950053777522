<template>
  <div class="coordinate-title">Углы поворота</div>
  <div class="coordinate-inputs">
    <BaseInputText
      type="number"
      name="x"
      label="X:"
      @input="changeCoordinate($event, Axis.x)"
      :value="coordinate.x"
      disabled
    />
    <BaseInputText
      type="number"
      name="y"
      label="Y:"
      @input="changeCoordinate($event, Axis.y)"
      :value="coordinate.y"
      disabled
    />
    <BaseInputText
      type="number"
      name="z"
      label="Z:"
      @input="changeCoordinate($event, Axis.z)"
      :value="coordinate.z"
      disabled
    />
  </div>
</template>

<script src="./CoordinateBlock.ts" lang="ts"/>
<style src="./CoordinateBlock.scss" lang="scss"/>
