<template>
  <Column
    title="ракурсы"
    @closedColumn="closedColumn"
  >
    <template v-slot:content>
      <BaseCarousel
        v-if="hasScreenshots"
        :data="dataForCarousel"
      />
      <div v-else>
        Нет скриншотов
      </div>
    </template>
  </Column>
</template>

<script src="./ColumnForeshortening.ts" lang="ts"/>
<style src="./ColumnForeshortening.scss" lang="scss"/>
