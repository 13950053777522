import { Foreshortening } from '@/types/foreshorteningTypes';
import { deepFreeze } from '@/utils/deepFreeze';

export const coordinateSetting = deepFreeze({
  [Foreshortening.LEFT]: { x: 0, y: -90, z: 0 },
  [Foreshortening.RIGHT]: { x: 0, y: 90, z: 0 },
  [Foreshortening.TOP]: { x: -90, y: 0, z: 0 },
  [Foreshortening.BOTTOM]: { x: 90, y: 0, z: 0 },
  [Foreshortening.FRONT]: { x: 0, y: 0, z: 0 },
  [Foreshortening.BACK]: { x: -180, y: 0, z: -180 },
  [Foreshortening.ANGLE]: { x: 45, y: 45, z: 0 }
});
