import { computed, defineComponent } from 'vue';
import Column from '@/components/Model/PageModelView/components/Column/Column.vue';
import { useStore } from 'vuex';
import BaseCarousel from '@/components/BaseComponents/BaseCarousel/BaseCarousel.vue';
import { FULL_PATH } from '@/services/apiService';
import type { TScreenshots } from '@/store/modules/models/state';

export default defineComponent({
  name: 'ColumnForeshortening',

  components: {
    Column,
    BaseCarousel
  },

  emits: ['closedColumn'],

  setup(_, { emit }) {
    const { state } = useStore();

    const closedColumn = () => {
      emit('closedColumn');
    };

    const hasScreenshots = computed(() => {
      return !!state.models.modelScreenShots.length;
    });

    const dataForCarousel = computed(() => {
      return state.models.modelScreenShots.map((item: TScreenshots) => {
        return {
          imgUrl: `${FULL_PATH}/model/uuid/${state.models.modelInfo.modelUuid}/screenshot/${item.id}`,
          text: item.commentary
        };
      });
    });

    return {
      closedColumn,
      dataForCarousel,
      hasScreenshots,
      state
    };
  }
});
