import { Plane, MeshStandardMaterial, DoubleSide, PerspectiveCamera } from 'three';

export const clipPlane = new Plane();

export const material = new MeshStandardMaterial({
  emissive: 0x000009,
  clipIntersection: true,
  side: DoubleSide,
  clippingPlanes: [clipPlane],
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  metalness: 0.6,
  transparent: true
});

export const coloredMaterial = new MeshStandardMaterial({
  clipIntersection: true,
  side: DoubleSide,
  clippingPlanes: [clipPlane],
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
  transparent: true,
  vertexColors: true
});

export const wireFrameMaterial = {
  color: 0x44cc33,
  polygonOffset: true,
  clippingPlanes: [clipPlane],
  clipIntersection: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1
};

export const setDefaultCamera = (element: Element): PerspectiveCamera => {
  return new PerspectiveCamera(75, element.clientWidth / element.clientHeight, 0.1, 1000000);
};

export const planeColor = 0xff5555;

export const rotationSpeed = 2;

export const minDistance = 0.1;

export const axisHelperSize = 180;

export const lightColor = 0xffffff;
