import { defineComponent, PropType } from 'vue';
import type { TColumnPosition } from '@/types/modelViewerTypes';

export default defineComponent({
  name: 'Column',

  props: {
    title: {
      type: String,
      required: true
    },
    columnPosition: {
      type: String as PropType<TColumnPosition>,
      default: 'right'
    },
    className: {
      type: String
    }
  },

  emits: ['closedColumn'],

  setup(_, { emit }) {
    const handlerClose = () => {
      emit('closedColumn');
    };

    return {
      handlerClose
    };
  }
});
