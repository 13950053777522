import { hasRmtsAccess } from '@/utils/rolesUtils';
import type { TUser } from '@/store/modules/users/state';
import { RegisteredModelTypes } from '@/types/modelType';

export const checkPermissionsForLink = (
  e: MouseEvent,
  user: TUser,
  listRmts: RegisteredModelTypes[],
  callBack: Function,
  ctrlCallBack: Function,
  failureCallBack: Function
) => {
  e.preventDefault();
  if (hasRmtsAccess(user, listRmts)) {
    e.ctrlKey ? ctrlCallBack() : callBack();
  } else {
    failureCallBack();
  }
};
