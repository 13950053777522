import { defineComponent, ref, watch } from 'vue';
import BaseSlider from '@/components/BaseComponents/BaseSlider/BaseSlider.vue';
import BaseInputNumber from '@/components/BaseComponents/BaseInputNumber/BaseInputNumber.vue';

export default defineComponent({
  name: 'SecantPlaneBlock',

  components: {
    BaseSlider,
    BaseInputNumber
  },

  props: {
    label: {
      type: String
    },
    minFractionDigits: {
      type: Number,
      default: null
    },
    maxFractionDigits: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    value: {
      type: Number
    }
  },

  emits: ['changeSecantValue'],

  setup(props, { emit }) {
    const blockValue = ref(props.value);

    const changeValue = (e: number) => {
      blockValue.value = +e;
    };

    const changeValueSlider = (value: number) => {
      blockValue.value = value;
    };

    watch(() => blockValue.value, () => {
      emit('changeSecantValue', blockValue.value);
    });

    return {
      blockValue,
      changeValue,
      changeValueSlider
    };
  }
});
