import * as THREE from 'three';
import type { BufferGeometry } from 'three';
import type { TStlData, TStlViewerOptions, TDetailInfo } from '@/types/stlViewerTypes';
import { chunkSize } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const STLLoader = require('./stlLoader')(THREE);

const loadSTLDetail = (detailInfo: TDetailInfo, options: TStlViewerOptions): Promise<TStlData> => {
  return new Promise((resolve, reject) => {
    try {
      const loader = new STLLoader(options);
      loader.load(
        detailInfo.url,
        (geometry: BufferGeometry) => {
          resolve({
            geometry,
            detailId: detailInfo.detailId
          });
        },
        () => null,
        reject
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const loadModel = async(modelPathArr: TDetailInfo[], options: TStlViewerOptions): Promise<TStlData[]> => {
  let stlDataArray: TStlData[] = [];
  for (let i = 0; i <= (modelPathArr.length / chunkSize); i++) {
    const partsInfoPromises = modelPathArr.slice(i * chunkSize, (i + 1) * chunkSize).map((item: TDetailInfo) => {
      return loadSTLDetail(item, options);
    });
    stlDataArray = stlDataArray.concat(await Promise.all(partsInfoPromises));
    options.onProgressCallback && options.onProgressCallback(i);
  }
  return stlDataArray;
};
