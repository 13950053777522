<template>
  <div class="scene-3d" :id="scenesIds.mainScene"></div>
  <div class="scene-3d scene-inner" :id="scenesIds.innerScene"></div>
  <BaseLoader v-if="isLoadingModel">
    <template v-slot:loader-content>
      <div class="p-d-flex p-dir-col p-align-center p-jc-center">
        <div class="progress-text">{{ progressInfo }}</div>
        <i class="pi pi-spin pi-spinner"></i>
      </div>
    </template>
  </BaseLoader>
</template>

<script src="./Scene.ts" lang="ts"/>
<style src="./Scene.scss" lang="scss"/>
