import type { TCoordinate } from '@/types/coordinatesType';
import { radianToGrad } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/coefficients';

export const isEqualCoordinate = (coordinate: TCoordinate, prevEmit: TCoordinate): boolean => {
  return (
    prevEmit.x === radianToGrad(coordinate.x) &&
    prevEmit.y === radianToGrad(coordinate.y) &&
    prevEmit.z === radianToGrad(coordinate.z)
  );
};
