<template>
  <VueEasyLightbox
    class="ui-lightbox"
    :visible="visible"
    :imgs="imgs"
    :index="activeIndex"
    @hide="handleHide"
  />
</template>

<script src="./BaseLightbox.ts" lang="ts"/>
<style src="./BaseLightbox.scss" lang="scss"/>
