export type TCoordinate = {
  x: number;
  y: number;
  z: number;
}

export enum Axis {
  x = 'x',
  y = 'y',
  z = 'z'
}
