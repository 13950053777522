<template>
  <router-link
    :to="{ name: ROUTE_NAMES.PAGE_SEARCH, params: { id: data.id } }"
    target="_blank"
    class="link-block"
  >
    <div class="created-block">
      <span class="created-date">{{ createdAt }}</span>
      <span class="created-text">
        Алгоритм поиска: {{ compareType(data.compareType ) }}
      </span>
    </div>
    <div class="count-wrapper">
      <div class="count">
        Моделей: {{ data.modelModelCount }}
      </div>
      <div class="count">
        Сравнений: {{ data.compareResultsCount }}
      </div>
    </div>

  </router-link>
</template>

<script src="./SearchLinkBlock.ts" lang="ts"/>
<style src="./SearchLinkBlock.scss" lang="scss"/>
