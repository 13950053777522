<template>
  <div class="ui-slider-wrapper">
    <span class="min-value">{{ min }}</span>
    <span class="mid-value">{{ (max + min) / 2 }}</span>
    <span class="max-value">{{ max }}</span>
    <Slider
      class="ui-slider"
      v-model="value"
      @change="change($event)"
      :min="minValue"
      :max="maxValue"
      :step="stepValue"
    />
  </div>
</template>

<script src="./BaseSlider.ts" lang="ts"/>
<style src="./BaseSlider.scss" lang="scss"/>
