import { defineComponent, ref } from 'vue';
import Column from '@/components/Model/PageModelView/components/Column/Column.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import ModelPartLine
  from '@/components/Model/PageModelView/components/ColumnModelPart/components/ModelPartLine/ModelPartLine.vue';
import { useStore } from 'vuex';
import { HTMLInputEvent } from '@/types/eventTypes';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_MUTATIONS_TYPE } from '@/store/names/mutations.names';

export default defineComponent({
  name: 'ColumnModelPart',

  components: {
    Column,
    BaseInputText,
    BaseIconButton,
    ModelPartLine,
    BaseButton
  },

  emits: ['closedColumn'],

  setup(_, { emit }) {
    const settingPopOverRef = ref();
    const searchValue = ref('');

    const { state, commit } = useStore();

    const changeSearchValue = (e: HTMLInputEvent) => {
      searchValue.value = e.target.value;
      commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_SEARCH}`, searchValue.value);
    };

    const closedColumn = () => {
      emit('closedColumn');
    };

    return {
      changeSearchValue,
      searchValue,
      settingPopOverRef,
      state,
      closedColumn
    };
  }
});
