import { computed, defineComponent, PropType, ref } from 'vue';
import Carousel from 'primevue/carousel';
import type { TSlide } from '@/types/slideType';
import BaseLightbox from '@/components/BaseComponents/BaseLightbox/BaseLightbox.vue';
import { getAuthImage } from '@/utils/getAuthImage';

export default defineComponent({
  name: 'BaseIconButton',

  props: {
    data: {
      type: Object as PropType<TSlide[]>,
      required: true
    }
  },

  components: {
    Carousel,
    BaseLightbox
  },

  setup(props) {
    const carouselRef = ref();
    const activeSlide = ref(0);
    const lightboxRef = ref();

    const isActiveSlide = (slideNumber: number): boolean => {
      return slideNumber === activeSlide.value;
    };

    const updatePage = (slideNumber: number): void => {
      activeSlide.value = slideNumber;
    };

    const toSlide = (slideNumber: number): void => {
      carouselRef.value.step('', slideNumber);
    };

    const openLightbox = (index: number) => {
      lightboxRef.value.showImage(index);
    };

    const imagesUrl = computed(() => {
      return props.data.map((item: TSlide) => {
        return getAuthImage(item.imgUrl);
      });
    });

    return {
      imagesUrl,
      lightboxRef,
      openLightbox,
      toSlide,
      isActiveSlide,
      updatePage,
      carouselRef,
      getAuthImage
    };
  }
});
