import { defineComponent, PropType } from 'vue';
import Column from '@/components/Model/PageModelView/components/Column/Column.vue';
import SecantPlaneBlock
  from '@/components/Model/PageModelView/components/ColumnSecantPlane/components/SecantPlaneBlock/SecantPlaneBlock.vue';
import type { TSecant } from '@/types/secantType';
import { Secant } from '@/types/secantType';

export default defineComponent({
  name: 'ColumnSecantPlane',

  components: {
    Column,
    SecantPlaneBlock
  },

  props: {
    secantValues: {
      type: Object as PropType<TSecant>,
      required: true
    }
  },

  emits: ['closedColumn', 'changeSecantValue'],

  setup(_, { emit }) {
    const closedColumn = () => {
      emit('closedColumn');
    };

    const changeSecantValue = (secantValue: TSecant, secantType: Secant) => {
      emit('changeSecantValue', {
        secantValue,
        secantType
      });
    };

    return {
      closedColumn,
      changeSecantValue,
      Secant
    };
  }
});
