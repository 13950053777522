import { defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import type { TModelData, TModelPartList } from '@/types/modelType';
import type { TCoordinate } from '@/types/coordinatesType';
import { loadStlViewer } from './utils/stlViewer';
import appendToastMessage from '@/utils/appendToastMessage';
import DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { Foreshortening } from '@/types/foreshorteningTypes';
import type { TSecant } from '@/types/secantType';
import type { TLeftColumnState } from '@/types/modelViewerTypes';
import { scenesId } from '@/components/Model/PageModelView/components/SceneBlock/constants/scenesId';
import { FULL_PATH } from '@/services/apiService';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { defaultPlaneValues, TPlaneValues } from '@/components/Model/PageModelView/components/SceneBlock/constants/defaultPlaneValues';
import type { TDetailInfo, TScenesIds, TStlViewer, TStlViewerOptions, TMeshData } from '@/types/stlViewerTypes';
import { animate } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/animateScene';
import { switchForeshortening } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/foreshorteningSwitcher';
import { kCos, kSin, radianToGrad } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/coefficients';
import { isEqualCoordinate } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/compareCoordinates';
import { chunkSize } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/constants';
import { handleResizeScene } from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/resizeScene';
import { useStore } from 'vuex';
import type { TMeshState } from '@/store/modules/models/state';
import { findMesh, watchMeshGroupState } from '@/components/Model/utils/setMeshState';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Scene',

  components: {
    BaseLoader
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    },
    coordinate: {
      type: Object as PropType<TCoordinate>,
      required: true
    },
    secantValues: {
      type: Object as PropType<TSecant>,
      required: true
    },
    leftColumnState: {
      type: Object as PropType<TLeftColumnState>,
      required: true
    },
    isForCompare: {
      type: Boolean,
      default: false
    },
    scenesIds: {
      type: Object as PropType<TScenesIds>,
      required: true
    },
    comparePartUuid: {
      type: String
    }
  },

  emits: ['changeCamera'],

  setup(props, { emit }) {
    let viewer: TStlViewer;
    let prevEmit: TCoordinate;
    const isLoadingModel = ref(true);
    const planeAngle = ref<TPlaneValues>({ ...defaultPlaneValues });
    const progressInfo = ref('');
    const { state, commit } = useStore();
    const { query } = useRoute();
    const sceneAnimateId = ref();

    const clickForeshorteningTypeButton = (foreshorteningType: Foreshortening): void => {
      switchForeshortening(foreshorteningType, viewer);
    };

    const updateSecantPlane = (): void => {
      planeAngle.value.x = kSin(props.secantValues.incline) * kCos(props.secantValues.turn);
      planeAngle.value.y = kSin(props.secantValues.incline) * kSin(props.secantValues.turn);
      planeAngle.value.z = kCos(props.secantValues.incline);
      planeAngle.value.const = props.secantValues.position;
    };

    watch(() => props.secantValues, () => {
      updateSecantPlane();
    });

    const mapAllMeshState = (mesh: TMeshData, arr: TMeshState[]): void => {
      arr.forEach((item: TMeshState) => {
        if (item.id === mesh.mesh.userData?.id) {
          mesh.mesh.visible = item.isVisible;
          mesh.wireframe.visible = item.isVisible;
        } else {
          if (item.children.length) {
            mapAllMeshState(mesh, item.children);
          }
        }
      });
    };

    watch(() => state.models.meshState, () => {
      viewer.meshDataArray.forEach((mesh: TMeshData) => {
        mapAllMeshState(mesh, state.models.meshState);
      });
    });

    watch(() => props.leftColumnState, () => {
      if (props.leftColumnState.isOpenSecantPlane) {
        updateSecantPlane();
      } else {
        planeAngle.value.const = defaultPlaneValues.const;
      }
    });

    const tick = (): void => {
      sceneAnimateId.value = requestAnimationFrame(tick);
      animate(viewer, props.leftColumnState, planeAngle);
      if (!isEqualCoordinate(viewer.camera.rotation, prevEmit)) {
        prevEmit = {
          x: radianToGrad(viewer.camera.rotation.x),
          y: radianToGrad(viewer.camera.rotation.y),
          z: radianToGrad(viewer.camera.rotation.z)
        };
        emit('changeCamera', prevEmit);
      }
    };

    const val = ref<TDetailInfo[]>([]);
    const makeDetailArr = (data: TModelPartList[] | undefined) => {
      data && data.map((item: TModelPartList) => {
        if (!item.children.length) {
          return val.value.push({
            url: `${FULL_PATH}/model/uuid/${props.data.modelUuid}/part/${item.uuid}/stl`,
            detailId: item.id
          });
        } else {
          return makeDetailArr(item.children);
        }
      });
      return val.value;
    };

    onMounted(async() => {
      isLoadingModel.value = true;
      if (props.data.modelPartList?.length) {
        progressInfo.value = 'Деталей загружено: 0';
      }
      try {
        let detailInfoArr = !props.comparePartUuid
          ? makeDetailArr(props.data.modelPartList!) : [{
            url: `${FULL_PATH}/model/uuid/${props.data.modelUuid}/part/${props.comparePartUuid}/stl`,
            detailId: 0
          }];

        const hasDetail = !detailInfoArr?.length;
        if (hasDetail) {
          detailInfoArr = [{
            url: `${FULL_PATH}/model/uuid/${props.data.modelUuid}/stl`,
            detailId: 0
          }];
        }

        const viewerOptions: TStlViewerOptions = {
          onProgressCallback: (i: number) => {
            progressInfo.value = `Деталей загружено: ${chunkSize * (i + 1)} из ${detailInfoArr!.length!}`;
          }
        };
        if (localStorage.getItem('JWT')) {
          viewerOptions.authHeader = { Authorization: `Bearer ${localStorage.getItem('JWT')}` };
        }

        viewer = await loadStlViewer(
          detailInfoArr!,
          [props.scenesIds.mainScene, props.scenesIds.innerScene],
          viewerOptions
        );

        window.addEventListener('resize', () => handleResizeScene(viewer));

        prevEmit = {
          x: radianToGrad(viewer.camera.rotation.x),
          y: radianToGrad(viewer.camera.rotation.y),
          z: radianToGrad(viewer.camera.rotation.z)
        };

        tick();

        isLoadingModel.value = false;
      } catch (err) {
        console.error(err);
        appendToastMessage(DICTIONARY.SERVER_ERROR);
      }
      if (query.partUuid) {
        const newMeshesState = watchMeshGroupState(findMesh(query.partUuid.toString(), state.models.meshState));
        commit(`${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_MESH_STATE}`, newMeshesState);
      }
    });

    onUnmounted(() => {
      // eslint-disable-next-line no-unused-expressions
      viewer?.renderer?.forceContextLoss();
      cancelAnimationFrame(sceneAnimateId.value);
      window.removeEventListener('resize', () => handleResizeScene(viewer));
    });

    const changeRenderType = (boolean: boolean) => {
      viewer.meshDataArray.forEach((mesh: TMeshData) => {
        mesh.wireframe.material.opacity = boolean ? 1 : 0;
      });
    };

    return {
      progressInfo,
      changeRenderType,
      isLoadingModel,
      clickForeshorteningTypeButton,
      scenesId,
      planeAngle
    };
  }
});
