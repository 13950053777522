<template>
  <BaseForm
    :handleSubmit='submitLoginForm'
    class="ui-add-foreshortening-form"
  >
    <template v-slot:form-content>
      <BaseTextArea
        name="text"
        label="Комментарий к ракурсу"
      />
      <div class="split-line"></div>
      <div class="p-d-flex p-jc-end ui-button-group">
        <BaseButton
          :disabled="isSubmitting"
          outlined
          label="Отменить"
          type="reset"
          @clicked="handleClickClosePopUp"
        />
        <BaseButton
          :disabled="isSubmitting"
          label="Сохранить"
        />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormAddForeshortening.ts" lang="ts"/>
<style src="./FormAddForeshortening.scss" lang="scss"/>
