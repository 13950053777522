import { defineComponent, ref, onMounted, watch, PropType } from 'vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import type { HTMLInputEvent } from '@/types/eventTypes';
import type { TCoordinate } from '@/types/coordinatesType';
import { Axis } from '@/types/coordinatesType';
import _ from 'lodash';

export default defineComponent({
  name: 'CoordinateBlock',

  components: {
    BaseInputText
  },

  props: {
    coordinateProp: {
      type: Object as PropType<TCoordinate>,
      required: true
    }
  },

  emits: ['changeCoordinate'],

  setup(props, { emit }) {
    const coordinate = ref(props.coordinateProp);

    watch(() => props.coordinateProp, () => {
      coordinate.value = props.coordinateProp;
    });

    onMounted(() => {
      emit('changeCoordinate', coordinate.value);
    });

    const changeCoordinate = (e: HTMLInputEvent, axis: Axis) => {
      coordinate.value = _.cloneDeep(coordinate.value);
      coordinate.value[axis] = +e.target.value;
      emit('changeCoordinate', coordinate.value);
    };

    return {
      coordinate,
      Axis,
      changeCoordinate
    };
  }
});
