import _ from 'lodash';

export const preprocessText = (text: string): string => {
  const arr = ['\\', '[', ']', '^', '$', '.', '|', '?', '*', '+', '(', ')'];

  arr.forEach((v) => {
    text = text.replace(new RegExp(`\\${v}`, 'g'), (s) => {
      return '\\' + s;
    });
  });

  return text;
};

export const highlightText = (text: string, query: string) => {
  query = preprocessText(query);
  query = _.escape(query);
  text = _.escape(text);
  if (!query) {
    return { string: text, isMatched: true };
  };
  const iQuery = new RegExp(query, 'gi');
  const isMatched = !!text.toString().match(iQuery);
  const searchedString = text.toString().replace(iQuery, (matchedTxt) => {
    return ('<mark class=\'highlight\'>' + matchedTxt + '</mark>');
  });
  return {
    string: searchedString,
    isMatched
  };
};
