import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import type { TDetailInfo, TMeshData, TStlData, TStlViewerOptions, TStlViewer } from '@/types/stlViewerTypes';
import { loadModel } from './loadStlModel';
import {
  clipPlane,
  setDefaultCamera,
  coloredMaterial,
  material,
  axisHelperSize,
  lightColor,
  minDistance,
  rotationSpeed,
  planeColor, wireFrameMaterial
} from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/utils/sceneProperties';

export const loadStlViewer =
  async(modelPathArr: TDetailInfo[], elementAppendID: string[], options: TStlViewerOptions): Promise<TStlViewer> => {
    const sceneElement = document.getElementById(elementAppendID[0]);
    const axisSceneElement = document.getElementById(elementAppendID[1]);
    if (!(sceneElement && axisSceneElement)) {
      throw new Error('no matches element ID');
    }

    // main scene
    const camera = setDefaultCamera(sceneElement);

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true
    });
    renderer.localClippingEnabled = true;
    renderer.setSize(sceneElement.clientWidth, sceneElement.clientHeight);

    sceneElement.appendChild(renderer.domElement);

    const controls = new TrackballControls(camera, renderer.domElement);
    controls.minDistance = minDistance;
    controls.rotateSpeed = rotationSpeed;
    controls.noPan = true;

    const scene = new THREE.Scene();

    const loadedModel = await loadModel(modelPathArr, options);

    const meshDataArray = loadedModel.map((stlData: TStlData) => {
      if (stlData.geometry?.hasColors) {
        stlData.geometry.computeBoundingBox();
        stlData.geometry.computeFaceNormals && stlData.geometry.computeFaceNormals();
        stlData.geometry.computeVertexNormals();
      }
      const mesh = new THREE.Mesh(stlData.geometry, stlData.geometry?.hasColors ? coloredMaterial : material);

      scene.add(mesh);
      const middle = new THREE.Vector3();
      stlData.geometry.computeBoundingBox();
      stlData.geometry.boundingBox && stlData.geometry.boundingBox.getCenter(middle);
      mesh.userData = { id: Number(stlData.detailId) };

      const geo = new THREE.WireframeGeometry(stlData.geometry);
      const mat = new THREE.LineBasicMaterial(wireFrameMaterial);
      const wireframe = new THREE.LineSegments(geo, mat);
      scene.add(wireframe);
      wireframe.userData = { id: Number(stlData.detailId) };
      wireframe.material.transparent = true;
      wireframe.material.opacity = 0;
      return {
        mesh,
        wireframe,
        middle
      };
    });

    const resultMiddle = {
      x: meshDataArray.reduce((sum: number, current: TMeshData) => (sum + current.middle.x), 0) / meshDataArray.length,
      y: meshDataArray.reduce((sum: number, current: TMeshData) => (sum + current.middle.y), 0) / meshDataArray.length,
      z: meshDataArray.reduce((sum: number, current: TMeshData) => (sum + current.middle.z), 0) / meshDataArray.length
    };

    meshDataArray.forEach((mesh: TMeshData) => {
      mesh.mesh.geometry.applyMatrix4(
        new THREE.Matrix4().makeTranslation(-resultMiddle.x, -resultMiddle.y, -resultMiddle.z)
      );
      mesh.wireframe.geometry.applyMatrix4(
        new THREE.Matrix4().makeTranslation(-resultMiddle.x, -resultMiddle.y, -resultMiddle.z)
      );
    });

    let largestDimension = 0;
    loadedModel.forEach((item: TStlData) => {
      const max = Math.max(
        item.geometry.boundingBox?.max.x || 0,
        item.geometry.boundingBox?.max.y || 0,
        item.geometry.boundingBox?.max.z || 0
      );
      largestDimension = ((max > largestDimension) ? max : largestDimension);
    });
    camera.position.z = largestDimension * 2;

    const light = new THREE.PointLight(lightColor);
    scene.add(light);

    // axis scene
    const axisScene = new THREE.Scene();
    const axis = new THREE.AxesHelper(axisHelperSize);
    const axisCamera = setDefaultCamera(axisSceneElement);
    const axisRenderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    axisRenderer.setSize(axisSceneElement.clientWidth, axisSceneElement.clientHeight);
    axisSceneElement.appendChild(axisRenderer.domElement);
    axisCamera.up = camera.up;
    axisScene.add(axis);
    axisScene.add(axisCamera);

    // add plane
    const plane = new THREE.Group();
    plane.add(new THREE.PlaneHelper(clipPlane, largestDimension * 2.5, planeColor));
    scene.add(plane);

    return {
      clipPlane,
      plane,
      axisScene,
      axisCamera,
      axisRenderer,
      camera,
      largestDimension,
      meshDataArray,
      sceneElement,
      light,
      controls,
      renderer,
      scene
    };
  };
