<template>
  <div class="tree-line">
    <div :class="`${!data.isVisible && 'hidden'} model-part-block`">
      <div :class="`${!!data.children.length && 'bold'} part-name`">
        <span @click="toggleGroup()" v-if="data.children.length" class="toggle-button">
          <template v-if="isOpenedLine">
            <i class="pi pi-angle-up"></i>
          </template>
          <template v-else>
            <i class="pi pi-angle-down"></i>
          </template>
        </span>
        <span :class="highlightedText.isMatched && searchValue && 'matched'" v-html="highlightedText.string"></span>
      </div>
      <div class="part-icon">
        <i @click="toggleVisibleMesh(data.id)" v-if="data.isVisible" class="pi pi-eye"/>
        <i @click="toggleVisibleMesh(data.id)" v-else class="pi pi-eye-slash"/>
      </div>
    </div>
    <div v-show="isOpenedLine" class="sub-tree">
      <ModelPartLine
        :search-value="searchValue"
        v-for="(item, index) in data.children"
        :data="item"
        :key="index"
      />
    </div>
  </div>
</template>

<script src="./ModelPartLine.ts" lang="ts"/>
<style src="./ModelPartLine.scss" lang="scss"/>
