import { defineComponent } from 'vue';
import Column from '@/components/Model/PageModelView/components/Column/Column.vue';
import SearchLinkBlock
  from '@/components/Model/PageModelView/components/ColumnSearch/components/SearchLinkBlock/SearchLinkBlock.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ColumnSearch',

  components: {
    Column,
    SearchLinkBlock
  },

  emits: ['closedColumn'],

  setup(_, { emit }) {
    const { state } = useStore();

    const closedColumn = () => {
      emit('closedColumn');
    };

    return {
      closedColumn,
      state
    };
  }
});
