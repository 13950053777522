import * as THREE from 'three';
import type { Ref } from '@/types/refType';
import type { TPlaneValues } from '@/components/Model/PageModelView/components/SceneBlock/constants/defaultPlaneValues';
import type { TStlViewer } from '@/types/stlViewerTypes';
import type { TLeftColumnState } from '@/types/modelViewerTypes';

export const animate = (
  viewer: TStlViewer,
  leftColumnState: TLeftColumnState,
  planeAngle: Ref<TPlaneValues>
): void => {
  viewer.axisCamera.position.copy(viewer.camera.position);
  viewer.axisCamera.position.sub(viewer.controls.target);
  viewer.axisCamera.position.setLength(300);
  viewer.axisCamera.lookAt(viewer.axisScene.position);
  viewer.axisRenderer.render(viewer.axisScene, viewer.axisCamera);

  viewer.controls.update();
  viewer.renderer.render(viewer.scene, viewer.camera);
  viewer.light.position.set(viewer.camera.position.x, viewer.camera.position.y, viewer.camera.position.z);

  viewer.clipPlane.constant = (-viewer.largestDimension + (2 * viewer.largestDimension * planeAngle.value.const)) * 2;
  viewer.clipPlane.normal = new THREE.Vector3(
    planeAngle.value.x,
    planeAngle.value.y,
    planeAngle.value.z
  );
  viewer.plane.visible = leftColumnState.isOpenSecantPlane;
};
