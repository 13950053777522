export type AnyObject = {
  [key: string]: any;
}

export const deepFreeze = (obj: AnyObject) => {
  const propNames = Object.getOwnPropertyNames(obj);
  propNames.forEach((name: string) => {
    const prop = obj[name];
    if (typeof prop === 'object' && prop !== null) {
      deepFreeze(prop);
    }
  });

  return Object.freeze(obj);
};
