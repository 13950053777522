<template>
  <Carousel
    containerClass="ui-carousel"
    :value="data"
    :numVisible="1"
    :numScroll="1"
    @update:page="updatePage($event)"
    ref="carouselRef"
  >
    <template #item="propSlide">
      <div class="ui-slide">
        <button
          class="large-img-icon"
          @click="openLightbox(propSlide.index)"
        >
            <i class="pi pi-window-maximize"></i>
        </button>
        <div class="img-wrapper">
          <img :src="getAuthImage(propSlide.data.imgUrl)" alt="img">
        </div>
        <div class="commentary">{{ propSlide.data.text }}</div>
      </div>
    </template>
  </Carousel>
  <div class="ui-preview-slider-wrapper p-grid">
    <div
      class="ui-preview p-col p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4"
      v-for="(item, index) in data"
      :key="index"
    >
      <button
        @click="toSlide(index)"
        :class="`${isActiveSlide(index) && 'active'} preview-content`"
      >
        <img :src="getAuthImage(item.imgUrl)" alt="img"/>
      </button>
    </div>
  </div>
  <BaseLightbox
    ref="lightboxRef"
    :imgs="imagesUrl"
  />
</template>

<script src="./BaseCarousel.ts" lang="ts"/>
<style src="./BaseCarousel.scss" lang="scss"/>
