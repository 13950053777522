<template>
  <Column
    title="История поиска похожих"
    @closedColumn="closedColumn"
  >
    <template v-slot:content>
      <SearchLinkBlock
        v-for="(item, index) in state.models.modelSearchHistory.content"
        :data="item"
        :key="index"
      />
      <div v-if="!state.models.modelSearchHistory?.content?.length">Отсутствуют</div>
    </template>
  </Column>
</template>

<script src="./ColumnSearch.ts" lang="ts"/>
<style src="./ColumnSearch.scss" lang="scss"/>
