export const radianToGrad = (number: number): number => {
  return Math.round(number / Math.PI * 180 % 360);
};

export const kSin = (number: number): number => {
  return Math.sin(number / 180 * Math.PI);
};

export const kCos = (number: number): number => {
  return Math.cos(number / 180 * Math.PI);
};
