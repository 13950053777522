<template>
  <BaseIconButton
    tool-tip-text="Сплошной каркас"
    @clicked="handleClickWireFrameRender()"
    :isActive="rightColumnState.isWireFrameRendering"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/controlIcon/bold_wireframe.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/controlIcon/bold_wireframe-w.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    v-if="!isForCompare"
    tool-tip-text="Части модели"
    @clicked="handleClickModelPart()"
    :isActive="rightColumnState.isOpenModelPart"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/controlIcon/parts.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/controlIcon/w-parts.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    v-if="!isForCompare"
    tool-tip-text="Плоскость"
    @clicked="handleClickSecantPlane()"
    :isActive="leftColumnState.isOpenSecantPlane"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/controlIcon/secant_plane.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/controlIcon/w-secant_plane.svg">
    </template>
  </BaseIconButton>
  <div class="line-horizontal"></div>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.FRONT)"
    tool-tip-text="Вид спереди"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/front.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.BACK)"
    tool-tip-text="Вид сзади"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/back.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.RIGHT)"
    tool-tip-text="Вид справа"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/right.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.LEFT)"
    tool-tip-text="Вид слева"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/left.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.TOP)"
    tool-tip-text="Вид сверху"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/top.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.BOTTOM)"
    tool-tip-text="Вид снизу"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/bottom.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="handleClickForeshorteningButton(Foreshortening.ANGLE)"
    tool-tip-text="Под углом"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/foreshorteningIcon/angle.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/search_models.svg">
    </template>
  </BaseIconButton>
</template>

<script src="./ControlBlock.ts" lang="ts"/>
<style src="./ControlBlock.scss" lang="scss"/>
