import { defineComponent, computed } from 'vue';
import ROUTE_NAMES from '@/router/route.names.json';
import moment from 'moment';
import { CompareType } from '@/store/modules/search/state';

export default defineComponent({
  name: 'SearchLinkBlock',

  components: {},

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const createdAt = computed(() => {
      return moment(props.data.startTime).locale('ru').format('DD MMMM YYYY');
    });

    const compareType = (type: CompareType) => {
      return (type === CompareType.NEURAL ? 'Нейросеть' : 'Аналитика');
    };

    return {
      compareType,
      ROUTE_NAMES,
      createdAt
    };
  }
});
