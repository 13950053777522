import { Foreshortening } from '@/types/foreshorteningTypes';
import type { TStlViewer } from '@/types/stlViewerTypes';

export const switchForeshortening = (foreshorteningType: Foreshortening, viewer: TStlViewer): void => {
  viewer.controls.reset();

  const largestDimension = viewer.largestDimension;

  switch (foreshorteningType) {
    case (Foreshortening.TOP):
      viewer.camera.position.set(0, largestDimension * 2, 0.01);
      viewer.camera.up.set(0, 0, -1);
      break;

    case (Foreshortening.BOTTOM):
      viewer.camera.position.set(0, -largestDimension * 2, 0.01);
      viewer.camera.up.set(0, 0, 1);
      break;

    case (Foreshortening.LEFT):
      viewer.camera.position.set(-largestDimension * 2, 0, 0.01);
      break;

    case (Foreshortening.RIGHT):
      viewer.camera.position.set(largestDimension * 2, 0, 0.01);
      break;

    case (Foreshortening.FRONT):
      viewer.camera.position.set(0, 0, largestDimension * 2);
      break;

    case (Foreshortening.BACK):
      viewer.camera.position.set(0, 0, -largestDimension * 2);
      break;

    case (Foreshortening.ANGLE):
      viewer.camera.position.set(
        largestDimension,
        largestDimension,
        largestDimension * Math.sqrt(2)
      );
      viewer.camera.lookAt(0, 0, 0);
      break;
  }
};
