import { computed, defineComponent, ref, watch } from 'vue';
import Slider from 'primevue/slider';

export default defineComponent({
  name: 'BaseRadioGroup',

  components: {
    Slider
  },

  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    valueProp: {
      type: Number,
      required: true
    }
  },

  emits: ['changed'],

  setup(props, { emit }) {
    const coefficient = 1 / props.step;

    const value = ref(props.valueProp * coefficient);

    const coefficientValue = (value: number) => {
      if (props.step >= 1) {
        return value;
      } else {
        return value * coefficient;
      }
    };

    const change = (e: number) => {
      value.value = e;
      emit('changed', value.value / coefficient);
    };

    watch(() => props.valueProp, () => {
      value.value = coefficientValue(props.valueProp);
    });

    const minValue = computed(() => {
      return coefficientValue(props.min);
    });

    const maxValue = computed(() => {
      return coefficientValue(props.max);
    });

    const stepValue = computed(() => {
      return coefficientValue(props.step);
    });

    return {
      value,
      stepValue,
      minValue,
      maxValue,
      change
    };
  }
});
