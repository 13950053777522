import { defineComponent } from 'vue';
import { object, string } from 'yup';
import { useForm } from 'vee-validate';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseTextArea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { ModelsHttpService } from '@/services/modelsService';
import { scenesId } from '@/components/Model/PageModelView/components/SceneBlock/constants/scenesId';
import appendToastMessage from '@/utils/appendToastMessage';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { maxCommentLength } from '@/dictionaries/validation/maxCommentLength';

export default defineComponent({
  name: 'FormAddForeshortening',

  components: {
    BaseForm,
    BaseTextArea,
    BaseButton
  },

  props: {
    modelId: {
      type: String
    }
  },

  emits: ['close'],

  setup(props, { emit }) {
    const modelsHttpService = new ModelsHttpService();
    const { dispatch } = useStore();
    const schema = object({
      text: string()
        .test('max_length', FORM_DICTIONARY.COMMENT_MAX_LENGTH, val => (val?.length || 0) < maxCommentLength)
    });

    const {
      handleSubmit,
      isSubmitting
    } = useForm({
      validationSchema: schema
    });

    const submitLoginForm = handleSubmit(async(values) => {
      const canvas = document.getElementById(scenesId.mainScene)?.getElementsByTagName('canvas')[0];
      const canvasURL = await canvas?.toDataURL('image/png');
      const screenshotData = {
        base64Data: canvasURL,
        commentary: values.text
      };
      try {
        await modelsHttpService.uploadScreenshot(props.modelId, screenshotData);
        appendToastMessage(FORM_DICTIONARY.SUCCESS, 'success');
        await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.FETCH_MODEL_SCREENSHOTS}`, props.modelId);
        emit('close');
      } catch (err) {
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      }
    });

    const handleClickClosePopUp = () => {
      emit('close');
    };

    return {
      submitLoginForm,
      handleClickClosePopUp,
      isSubmitting
    };
  }
});
