<template>
  <BaseIconButton
    tool-tip-text="Скачать PDF"
    @clicked="download3dpdf"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/pdf.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-pdf.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    v-if="isRenderingAccessibleByRmts && !isInternalUser(state.users.user)"
    @clicked="handleClickAddForeshortening"
    tool-tip-text="Добавить ракурс"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/add_image.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-add_image.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    v-if="isRenderingAccessibleByRmts"
    tool-tip-text="Поиск похожих моделей"
    @clicked="openSearchModal"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/search.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-search.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    @clicked="downloadFile"
    tool-tip-text="Скачать файл"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/download.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-download.svg">
    </template>
  </BaseIconButton>
  <div class="line-vertical"></div>
  <BaseIconButton
    tool-tip-text="Сохраненные ракурсы"
    @clicked="handleClickForeshorteningColumn"
    :isActive="rightColumnState.isOpenForeshortening"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/images.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-images.svg">
    </template>
  </BaseIconButton>
  <BaseIconButton
    v-if="isRenderingSearchHistory"
    tool-tip-text="История поиска"
    @clicked="handleClickSearchColumn"
    :isActive="rightColumnState.isOpenSearch"
  >
    <template v-slot:button-image>
      <img alt="img" src="@/assets/icons/actionIcon/history_search.svg">
    </template>
    <template v-slot:button-image-active>
      <img alt="img" src="@/assets/icons/actionIcon/w-history_search.svg">
    </template>
  </BaseIconButton>
</template>

<script src="./ActionBlock.ts" lang="ts"/>
<style src="./ActionBlock.scss" lang="scss"/>
