<template>
  <Column
    title="Части модели"
    column-position="right"
    @closedColumn="closedColumn"
    className="models-column"
  >
    <template v-slot:content>
      <div class="column-content">
        <div class="search-bar" id="search-bar">
          <BaseInputText
            name="search"
            icon="pi-search"
            placeholder="Поиск"
            :value="searchValue"
            @input="changeSearchValue($event)"
          />
        </div>
        <div class="detail-info-wrapper">
          <ModelPartLine
            :search-value="searchValue"
            v-for="(item, index) in state.models.meshState"
            :data="item"
            :key="index"
          />
          <div
            class="no-detail-block"
            v-if="!state.models?.meshState?.length"
          >
            Нет деталей
          </div>
        </div>
      </div>
    </template>
  </Column>

</template>

<script src="./ColumnModelPart.ts" lang="ts"/>
<style src="./ColumnModelPart.scss" lang="scss"/>
