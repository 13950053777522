<template>
  <div :class="`${columnPosition === 'left' && 'left-column'} right-column ${className}`">
    <div class="right-column-header">
      <div class="ui-title-small">{{ title }}</div>
      <button class="close-btn" @click="handlerClose()">×</button>
    </div>
    <div class="right-column-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script src="./Column.ts" lang="ts"/>
<style src="./Column.scss" lang="scss"/>
