<template>
  <div class="scene-wrapper">
    <BaseLoader v-if="isLoading || isProcessing">
      <template v-slot:loader-content v-if="isProcessing">
        <div class="ui-title-large">Модель в обработке</div>
      </template>
    </BaseLoader>
    <div
      v-if="isStlModel"
      class="scene"
    >
      <Scene
        v-if="!isLoading && !isProcessing"
        :data="data"
        :coordinate="coordinate"
        ref="sceneRef"
        @changeCamera="changeCamera($event)"
        :secant-values="secantValues"
        :left-column-state="leftColumnState"
        :scenes-ids="scenesIds"
        :is-for-compare="isForCompare"
        :compare-part-uuid="comparePartUuid"
      />
      <div class="scene-block control-block">
        <ControlBlock
          @clickModelPart="clickModelPartButton()"
          @clickSecantPlane="clickSecantPlaneButton()"
          @clickForeshorteningType="clickForeshorteningTypeButton($event)"
          @clickWireFrameRender="clickWireFrameRender()"
          :left-column-state="leftColumnState"
          :right-column-state="rightColumnState"
          :is-for-compare="isForCompare"
        />
      </div>
      <div v-if="!isForCompare" :class="`${isOpenRightColumn && 'opened'} ${rightColumnState.isOpenModelPart && 'full-opened'} scene-block action-block`">
        <ActionBlock
          @clickSearch="clickSearchButton()"
          @clickForeshortening="clickForeshorteningButton()"
          @clickAddForeshortening="clickAddForeshortening()"
          @openSearchModal="searchHandler"
          :right-column-state="rightColumnState"
          :data="data"
        />
      </div>
      <div class="scene-block coordinate-block">
        <CoordinateBlock
          @changeCoordinate="changeCoordinate($event)"
          :coordinateProp="coordinate"
        />
      </div>
      <ColumnSecantPlane
        v-if="!isForCompare"
        v-show="leftColumnState.isOpenSecantPlane"
        :secant-values="secantValues"
        @closedColumn="closeLeftColumn()"
        @changeSecantValue="changeSecantValue($event)"
      />
      <ColumnModelPart
        v-if="!isForCompare"
        v-show="rightColumnState.isOpenModelPart"
        @closedColumn="closeRightColumn()"
      />
      <ColumnSearch
        v-if="!isForCompare"
        v-show="rightColumnState.isOpenSearch"
        @closedColumn="closeRightColumn()"
      />
      <ColumnForeshortening
        v-if="!isForCompare && rightColumnState.isOpenForeshortening"
        @closedColumn="closeRightColumn()"
      />
    </div>
    <div
      v-else
      class="scene"
    >
      <div class="not-stl-text ui-title-medium">
        Модель доступна для просмотра в формате 3D PDF. Для просмотра&nbsp;
        <button class="ui-title-medium" @click="download3dpdf()">нажмите здесь</button>
      </div>
    </div>
  </div>
  <BasePopUp
    title="Сохранить ракурс"
    ref="addForeshorteningPopUpRef"
    class-name="foreshortening-popup"
  >
    <template v-slot:content>
      <FormAddForeshortening
        :model-id="data.modelUuid"
        @close="closePopUp"
      />
    </template>
  </BasePopUp>
  <BasePopUp
    className="start-search-modal"
    title="Поиск похожих моделей"
    ref="startSearchPopUpRef"
  >
    <template v-slot:content>
      <FormStartSearch
        :model-id="data.id"
        :model-uuid="data.modelUuid"
        @closePopup="searchHandler"
      />
    </template>
  </BasePopUp>
</template>

<script src="./SceneBlock.ts" lang="ts"/>
<style src="./SceneBlock.scss" lang="scss"/>
