<template>
  <div class="secant-block">
    <div class="slider-part">
      <div class="label">{{ label }}</div>
      <BaseSlider
        :max="max"
        :min="min"
        :step="step"
        :valueProp="blockValue"
        @changed="changeValueSlider($event)"
      />
    </div>
    <BaseInputNumber
      :format="false"
      :name="'position'+label"
      :value="blockValue"
      :max="max"
      :min="min"
      :step="step"
      @input="changeValue($event)"
      :minFractionDigits="minFractionDigits"
      :maxFractionDigits="maxFractionDigits"
    />
  </div>
</template>

<script src="./SecantPlaneBlock.ts" lang="ts"/>
<style src="./SecantPlaneBlock.scss" lang="scss" />
