import { defineComponent, PropType, ref } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';

export default defineComponent({
  name: 'BaseButton',

  components: {
    VueEasyLightbox
  },

  props: {
    imgs: {
      type: Array as PropType<string[]>,
      default: []
    }
  },

  setup() {
    const visible = ref(false);
    const activeIndex = ref(0);

    const showImage = (index: number) => {
      activeIndex.value = index;
      visible.value = true;
    };

    const handleHide = () => {
      visible.value = false;
    };

    return {
      activeIndex,
      visible,
      showImage,
      handleHide
    };
  }
});
