import { computed, defineComponent, PropType } from 'vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import type { TRightColumnState } from '@/types/modelViewerTypes';
import type { TModelData } from '@/types/modelType';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { hasRmtsAccess, isLeader, isInternalUser } from '@/utils/rolesUtils';

export default defineComponent({
  name: 'ActionBlock',

  components: {
    BaseIconButton
  },

  props: {
    rightColumnState: {
      type: Object as PropType<TRightColumnState>,
      required: true
    },
    data: {
      type: Object as PropType<TModelData>,
      required: true
    }
  },

  emits: ['clickSearch', 'clickForeshortening', 'clickAddForeshortening', 'openSearchModal'],

  setup(props, { emit }) {
    const { dispatch, state } = useStore();

    const openSearchModal = () => {
      emit('openSearchModal');
    };

    const handleClickSearchColumn = () => {
      emit('clickSearch');
    };

    const handleClickForeshorteningColumn = () => {
      emit('clickForeshortening');
    };

    const handleClickAddForeshortening = () => {
      emit('clickAddForeshortening');
    };

    const download3dpdf = () => {
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.DOWNLOAD_3DPDF}`, props.data.modelUuid);
    };

    const downloadFile = () => {
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.DOWNLOAD_FILE}`, {
        uuid: props.data.modelUuid,
        fileName: props.data.fileName
      });
    };

    const isRenderingSearchHistory = computed(() => {
      return (
        hasRmtsAccess(state.users?.user, [state.models?.modelInfo?.registeredModelType?.id]) ||
        isLeader(state.users?.user)
      );
    });

    const isRenderingAccessibleByRmts = computed(
      () => hasRmtsAccess(state.users?.user, [state.models?.modelInfo?.registeredModelType?.id])
    );

    return {
      state,
      isInternalUser,
      isRenderingAccessibleByRmts,
      isRenderingSearchHistory,
      openSearchModal,
      download3dpdf,
      downloadFile,
      handleClickSearchColumn,
      handleClickForeshorteningColumn,
      handleClickAddForeshortening
    };
  }
});
