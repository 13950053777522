import { computed, defineComponent, PropType, ref } from 'vue';
import ActionBlock from '@/components/Model/PageModelView/components/ActionBlock/ActionBlock.vue';
import ControlBlock from '@/components/Model/PageModelView/components/ControlBlock/ControlBlock.vue';
import CoordinateBlock from '@/components/Model/PageModelView/components/CoordinateBlock/CoordinateBlock.vue';
import ColumnSearch from '@/components/Model/PageModelView/components/ColumnSearch/ColumnSearch.vue';
import ColumnForeshortening
  from '@/components/Model/PageModelView/components/ColumnForeshortening/ColumnForeshortening.vue';
import ColumnModelPart from '@/components/Model/PageModelView/components/ColumnModelPart/ColumnModelPart.vue';
import ColumnSecantPlane from '@/components/Model/PageModelView/components/ColumnSecantPlane/ColumnSecantPlane.vue';
import Scene from '@/components/Model/PageModelView/components/SceneBlock/components/Scene/Scene.vue';
import { Foreshortening } from '@/types/foreshorteningTypes';
import type { TCoordinate } from '@/types/coordinatesType';
import { coordinateSetting } from '@/components/Model/PageModelView/components/SceneBlock/constants/cameraCoordinate';
import type { TModelData } from '@/types/modelType';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import FormAddForeshortening from '@/components/Forms/FormAddForeshortening/FormAddForeshortening.vue';
import { secantDefault } from '@/components/Model/PageModelView/components/SceneBlock/constants/secantDefault';
import _ from 'lodash';
import type { TSecantChange } from '@/types/secantType';
import { useStore } from 'vuex';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { ModelFileFormats } from '@/types/modelType';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import type { TScenesIds } from '@/types/stlViewerTypes';
import FormStartSearch from '@/components/Forms/FormStartSearch/FormStartSearch.vue';

export default defineComponent({
  name: 'SceneBlock',

  components: {
    ActionBlock,
    ControlBlock,
    CoordinateBlock,
    ColumnSearch,
    ColumnModelPart,
    ColumnForeshortening,
    ColumnSecantPlane,
    Scene,
    BasePopUp,
    FormAddForeshortening,
    BaseLoader,
    FormStartSearch
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    },
    scenesIds: {
      type: Object as PropType<TScenesIds>,
      required: true
    },
    isForCompare: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    comparePartUuid: {
      type: String
    },
    isProcessing: {
      type: Boolean
    }
  },

  setup(props) {
    const { state, dispatch } = useStore();

    const rightColumnState = ref({
      isOpenForeshortening: false,
      isOpenSearch: false,
      isOpenModelPart: false,
      isWireFrameRendering: false
    });

    const leftColumnState = ref({
      isOpenSecantPlane: false
    });

    const openSearch = () => {
      rightColumnState.value = {
        ...rightColumnState.value,
        isOpenForeshortening: false,
        isOpenSearch: true,
        isOpenModelPart: false
      };
    };

    const openForeshortening = () => {
      rightColumnState.value = {
        ...rightColumnState.value,
        isOpenForeshortening: true,
        isOpenSearch: false,
        isOpenModelPart: false
      };
    };

    const openModelPart = () => {
      rightColumnState.value = {
        ...rightColumnState.value,
        isOpenForeshortening: false,
        isOpenSearch: false,
        isOpenModelPart: true
      };
    };

    const closeRightColumn = () => {
      rightColumnState.value = {
        ...rightColumnState.value,
        isOpenForeshortening: false,
        isOpenSearch: false,
        isOpenModelPart: false
      };
    };

    const openSecantPlane = () => {
      leftColumnState.value = {
        isOpenSecantPlane: true
      };
    };

    const closeLeftColumn = () => {
      leftColumnState.value = {
        isOpenSecantPlane: false
      };
    };

    const isOpenRightColumn = computed(
      () => rightColumnState.value.isOpenForeshortening ||
        rightColumnState.value.isOpenSearch ||
        rightColumnState.value.isOpenModelPart
    );

    const clickSearchButton = () => {
      if (rightColumnState.value.isOpenSearch) {
        closeRightColumn();
      } else {
        openSearch();
      }
    };

    const clickForeshorteningButton = () => {
      if (rightColumnState.value.isOpenForeshortening) {
        closeRightColumn();
      } else {
        openForeshortening();
      }
    };

    const clickModelPartButton = () => {
      if (rightColumnState.value.isOpenModelPart) {
        closeRightColumn();
      } else {
        openModelPart();
      }
    };

    const clickSecantPlaneButton = () => {
      if (leftColumnState.value.isOpenSecantPlane) {
        closeLeftColumn();
      } else {
        openSecantPlane();
      }
    };

    const coordinate = ref(coordinateSetting[Foreshortening.FRONT]);
    const sceneRef = ref();

    const clickForeshorteningTypeButton = (foreshorteningDirection: Foreshortening) => {
      sceneRef.value.clickForeshorteningTypeButton(foreshorteningDirection);
    };

    const changeCoordinate = (inputCoordinates: TCoordinate) => {
      coordinate.value = inputCoordinates;
    };

    const changeCamera = (cameraCoordinate: TCoordinate) => {
      coordinate.value = cameraCoordinate;
    };

    const addForeshorteningPopUpRef = ref();

    const clickAddForeshortening = () => {
      addForeshorteningPopUpRef.value.handleModal();
    };

    const closePopUp = () => {
      addForeshorteningPopUpRef.value.handleModal();
    };

    const secantValues = ref(secantDefault);

    const changeSecantValue = (value: TSecantChange) => {
      secantValues.value = _.cloneDeep(secantValues.value);
      secantValues.value[value.secantType] = value.secantValue;
    };

    const isStlModel = computed(() => {
      return !(props.data.modelFileFormat?.id === ModelFileFormats.PRC);
    });

    const download3dpdf = () => {
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.DOWNLOAD_3DPDF}`, props.data.modelUuid);
    };

    const startSearchPopUpRef = ref();

    const searchHandler = () => {
      startSearchPopUpRef.value.handleModal();
    };

    const clickWireFrameRender = () => {
      rightColumnState.value.isWireFrameRendering = !rightColumnState.value.isWireFrameRendering;
      sceneRef.value.changeRenderType(rightColumnState.value.isWireFrameRendering);
    };

    return {
      clickWireFrameRender,
      searchHandler,
      startSearchPopUpRef,
      download3dpdf,
      isStlModel,
      state,
      changeCamera,
      sceneRef,
      openForeshortening,
      closePopUp,
      changeSecantValue,
      isOpenRightColumn,
      coordinate,
      changeCoordinate,
      addForeshorteningPopUpRef,
      rightColumnState,
      clickModelPartButton,
      clickSecantPlaneButton,
      leftColumnState,
      clickForeshorteningTypeButton,
      openModelPart,
      closeLeftColumn,
      clickSearchButton,
      clickAddForeshortening,
      openSecantPlane,
      openSearch,
      closeRightColumn,
      clickForeshorteningButton,
      secantValues
    };
  }
});
