export type TSecant = {
  position: number;
  turn: number;
  incline: number;
}

export enum Secant {
  position = 'position',
  turn = 'turn',
  incline = 'incline'
}

export type TSecantChange = {
  secantValue: number;
  secantType: Secant;
}
